// cookies.service.js
/* globals angular */
"use strict";

class cookiesService {
  constructor($transitions, gtag, $cookies) {
    this.$transitions = $transitions;
    this.gtag = gtag;
    this.$cookies = $cookies;
    this.consentGiven = true;
  }

  startRegisteringEvents() {
    this.gtag('js', new Date());
    this.gtag('config', 'G-9SRVZK5K90');
    this.$transitions.onSuccess({}, function(transition){
      if (transition._options.source === "url") {
        this.gtag('config', 'G-9SRVZK5K90', {'page_path': transition.to().url });
      }
    }.bind(this));
  }

  storePreferences(consent) {
    this.$cookies.put("sweno.consent", angular.toJson(consent), {samesite: 'lax', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 2))});
  }

  show() {
    return this.consentGiven;
  }

  setShow(value) {
    this.consentGiven = value;
  }
}

cookiesService.$inject = ["$transitions", "gtag", "$cookies"];

export { cookiesService as COOKIES_SERVICE };
