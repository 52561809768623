// auth.service.js
/* globals angular*/
"use strict";

class authService {
  constructor($cookies, $window) {
    this.$cookies = $cookies;
    this.$window = $window;
    this.authData = undefined;
    this.wokplace = {
      id: undefined,
      name: undefined,
      reference: undefined,
      code: undefined
    };
    this.agent = {
      id: undefined,
      name: undefined,
      number: undefined,
      email: undefined,
      type: undefined
    };
    this.typeMap = {
      "TELEVENTA": this.setTelephoneAgent.bind(this),
      "PRESENCIAL": this.setInPersonAgent.bind(this)
    };
    this.setLoginData(this.$cookies.get("sweno.workplace"));
  }

  setLoginData(data) {
    data = angular.fromJson(data);
    this.setAgentTypeData(data);
    this.setAgentData(data);
    this.setWorkplaceData(data);
  }

  setAgentTypeData(data) {
    if (!data?.type) {
      this.$window.location.href = "/login";
      return;
    }
    this.typeMap[data.type]();
  }

  setAgentData(data) {
    if (!data?.agent?.id || !data?.agent?.number) {
      this.$window.location.href = "/login";
      return;
    }
    this.agent.id = data.agent.id;
    this.agent.name = data.agent.name;
    this.agent.email = data.agent.email;
    this.agent.number = data.agent.number;
  }

  setWorkplaceData(data) {
    if (!this.isMandatoryWorkplace(data)) {
      return;
    }
    try{
      this.doSetWorkplaceData(data.workplace);
    } catch(ignore) {
      this.$window.location.href = "/login";
    }
  }

  doSetWorkplaceData(workplace) {
    this.wokplace.id = workplace.id;
    this.wokplace.name = workplace.name;
    this.wokplace.code = workplace.code;
    this.wokplace.reference = workplace.reference;
  }

  isMandatoryWorkplace(data) {
    if (this.isTelephoneAgent()) {
      return false;
    }
    if (!data?.workplace?.name) {
      this.$window.location.href = "/login";
    }
    return true;
  }

  getAccessTokenHeader() {
    if (!this.authData) {
        return;
    }
    return this.capitalize(this.authData.tokenType) + ' ' + this.authData.accessToken;
  }

  setAuthData(authData) {
    this.authData = Object.assign({}, authData);
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  clear() {
      this.authData = undefined;
  }

  isTelephoneAgent() {
    return this.agent.type === 'telephone';
  }

  setTelephoneAgent() {
    this.agent.type = 'telephone';
  }

  setInPersonAgent() {
    this.agent.type = 'in-person';
  }

  getAgentType() {
    return this.agent.type;
  }

  getWorkplace() {
    return Object.assign({}, this.wokplace);
  }

  getAgentData() {
    return Object.assign({}, this.agent);
  }
}

authService.$inject = ["$cookies", "$window"];

export { authService as AUTH_SERVICE };