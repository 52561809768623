// app.routing.js

"use strict";

import { contratacion } from "./app.states";

appRouting.$inject = ["$stateProvider", "$urlRouterProvider", "$locationProvider", "$urlMatcherFactoryProvider"];

function appRouting($stateProvider, $urlRouterProvider, $locationProvider, $urlMatcherFactoryProvider) {
  $urlMatcherFactoryProvider.strictMode(false);
  $urlRouterProvider.otherwise("/");
  $locationProvider.html5Mode(true);
  $stateProvider.state(contratacion);
}

export { appRouting };
