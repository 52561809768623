// app.js
"use strict";

import angular from "angular";
import "angular-i18n/angular-locale_es-es";
import "@uirouter/angularjs";
import "oclazyload";
import "bootstrap/dist/css/bootstrap.min.css";
import "./core/core.less";

import { appRouting } from "./app.routing";
import { scroll } from "./app.scrollToTop.run";
import { authInterceptor } from "./core/auth.interceptor";
import { configHttp } from "./core/config.http";
import { AUTH_SERVICE } from "./core/auth.service";
import { CONTRATACION_CONFIG_SERVICE } from "./core/contratacion-config.service";
import { COOKIES_MODULE } from "./components/cookies/cookies.module";


const AGENTES_SWENO_ENERGIA =  angular
    .module("sweno-energy.agent", [
        "ui.router",
        "oc.lazyLoad",
        COOKIES_MODULE
    ])
    .config(appRouting)
    .config(configHttp)
    .factory("authInterceptor", authInterceptor)
    .service("authService", AUTH_SERVICE)
    .service("contratacionConfig", CONTRATACION_CONFIG_SERVICE)
    .run(scroll)
    .name
    ;

export { AGENTES_SWENO_ENERGIA };
