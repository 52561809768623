// cookies.controller.js
"use strict";

class cookiesController {
  constructor(cookiesService, ngDialog) {
    this.service = cookiesService;
    this.ngDialog = ngDialog;
    this.analytics = true;
    this.consent = {
      statistics: false
    };
    this.show = this.service.show();
    this.dialogoCookies;
  }

  $onInit() {
    if (this.show) {
      this.dialogoCookies = this.ngDialog.open({
        template: '<cookies-dialog data-accept="ngDialogData.onaccept(analytics)"></cookies-dialog>',
        className: 'ngdialog-theme-default ngdialog-responsive',
        plain: true,
        showClose: false,
        closeByEscape : false,
        closeByDocument: false,
        data: {
          onaccept: this.onaccept(this.analytics)
        }
      });
    }
  }

  onaccept() {
    return (analytics) => {
      this.analytics = analytics;
      this.dialogoCookies.close();
      this.selection(true);
    };
  }

  $onChanges() {

  }

  selection(value) {
    this.service.setShow(false);
    this.show = false;
    this.consent.statistics = value;
    this.service.storePreferences(this.consent);
    if (this.analytics) {
      this.service.startRegisteringEvents();
    }
  }
}

cookiesController.$inject = ["cookiesService", "ngDialog"];

export { cookiesController as COOKIES_CONTROLLER };
