//cookies.run.js

"use strict";

import angular from "angular";

onRun.$inject = ["$cookies", "cookiesService"];
function onRun($cookies, cookiesService) {
  constructor();

  function constructor() {
    let cookies = $cookies.get("sweno.consent");
    const consent = cookies ? angular.fromJson(cookies) : undefined;
    if (consent) {
      cookiesService.setShow(false);
      if (consent.statistics) {
        cookiesService.startRegisteringEvents();
      }
    }
  }
}

export { onRun as COOKIES_RUN };
