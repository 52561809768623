// app.states.js

"use strict";

const contratacion = {
  name: "contratacion",
  url: "/",
  component: "contratacion",
  lazyLoad: ($transition$) => {
    const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
    return import(/* webpackChunkName: "contratacion.module" */ "@telecor-web/contratacion")
      .then(mod => $ocLazyLoad.inject(mod.CONTRATACION_MODULE))
      .catch(err => {
        throw new Error("Ooops, something went wrong, " + err);
      });
  },
  onEnter: ["$document", ($document) => {
    $document[0].title = "Contratación";
    $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Contrata Energia El Corte Ingles');
    $document[0].querySelector('meta[name="keyword"]').setAttribute('content', '');
    $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://contratacion.energiaelcorteingles.es/contratacion');
  }],
  resolve: {
    config: ["contratacionConfig", (contratacionConfig) => {
      return contratacionConfig.getConfig();
    }],
    origen: ["authService", (authService) => {
      return authService.getAgentType();
    }],
    workplace: ["authService", (authService) => {
      return authService.getWorkplace();
    }],
    agent: ["authService", (authService) => {
      return authService.getAgentData();
    }]
  }
};

export { contratacion };
